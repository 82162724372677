module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-intl@4.0.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0_jiti@1.21.6____twvwkydv7rmkv2cavytfsxazr4/node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/berlin-bootsverleih-frontend/berlin-bootsverleih-frontend/src/intl","languages":["de","en"],"defaultLanguage":"de","redirect":false,"redirectDefaultLanguageToRoot":true,"ignoredPaths":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0_jiti@1.21.6___enc_5zxuuppgkhghbprh755gqal74i/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-next-seo@1.11.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0_jiti@1.21.6___e_rnm6tuz3pz4rm7vmjvzgwe6uyi/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","site_name":"Berlin Bootsverleih"}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0_jiti@1.21.6___e_nzg62zio436ojdovtfojkeas3a/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Berlin Bootsverleih","short_name":"Berlin Bootsverleih","start_url":"/","background_color":"#E08D49","theme_color":"#E08D49","display":"standalone","icon":"static/img/favicon-logo.svg","icons":[{"src":"img/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"img/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"img/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"img/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"img/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"img/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"img/favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"img/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4312ecc81a83df16f24c46bfa809e896"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0_jiti@1_j7sotsfiwycjm4o4qswxo3vmcu/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WPRZGJG","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0_jiti@1.21.6___encoding@0.1.13_react-dom@18.3._ro4x3n22udw54fyiulhvw7tmly/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
